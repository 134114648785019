import { takeEvery } from 'typed-redux-saga';
import { tokenExpired } from '../actions';
import { renewToken } from './common/auth/renew-token';

function* handler() {
  yield renewToken();
}

export function* tokenExpiredWorkflow() {
  yield takeEvery(tokenExpired, handler)
}
