import { VideoPlayer } from './video-player';
import { useState } from 'react';
import { OptImg } from '../OptImg';
import styles from './video-loader.module.scss';
import React, { FunctionComponent } from 'react';
import '../../styles/libraries/_videojs.scss';

export interface VideoLoaderProps {
  videoSrc: string;
  aboveFold?: boolean;
  autoplay?: string;
  controls?: boolean;
  language?: string;
  loop?: boolean;
  muted?: boolean;
  placeholderSrc?: string;
  preload?: string;
  rounded?: boolean;
  size?: string;
  styleClass?: string;
  // for text track docs:  https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/textTracks
  textTracks?: Array<{ kind: string; src: string; srclang: string; autoShow: boolean }>;
  videoHeight?: number;
  videoMaxWidth?: number;
  videoWidth?: number;
}

export const VideoLoader: FunctionComponent<VideoLoaderProps> = ({
  aboveFold = false,
  autoplay = 'false',
  controls = true,
  language = 'en',
  muted = false,
  placeholderSrc = 'https://cdn.bfldr.com/I6FML9WY/at/9fpc5nk76vv4b74x3pr2rqk/smartsheet-brandfolder-header?format=jpg&auto=webp',
  preload = 'auto',
  loop = false,
  rounded = true,
  size = 'full',
  styleClass,
  textTracks = [],
  videoHeight = 720,
  videoSrc,
  videoMaxWidth = 960,
  videoWidth = 1280,
}) => {
  const [didInteract, setInteract] = useState(false);

  const videoOptions = {
    // documentation: https://docs.videojs.com/tutorial-options.html
    autoplay, // https://player.support.brightcove.com/playback/autoplay-considerations.html
    controls,
    fluid: true,
    language,
    loop,
    muted,
    playsInline: true,
    poster: placeholderSrc,
    preload,
    rounded,
    src: videoSrc,
    textTracks,
  };

  const widthModifier = size === 'full' ? 100 : 80;
  const videoHeightPadding = (videoHeight / videoWidth) * widthModifier;

  return (
    <>
      <div
        className={styles.video}
        style={{ maxWidth: `${videoMaxWidth}px` }}
        onClick={() => setInteract(true)}
        onKeyPress={() => setInteract(true)}
        role="button"
        tabIndex={0}
      >
        <div
          className={`${styles['video__container']} ${styleClass} ${rounded ? styles.rounded : ''}`}
          style={{ paddingBottom: `${videoHeightPadding}%` }}
        >
          <div className={`${styles['video__container--video']} ${styles[size]}`}>
            {didInteract === true || preload === 'auto' ? (
              <VideoPlayer {...videoOptions} />
            ) : (
              <>
                <OptImg
                  aboveFold={aboveFold}
                  alt="Video"
                  src={placeholderSrc}
                  nativeHeight={`${videoHeight}`}
                  nativeWidth={`${videoWidth}`}
                  widthOption="half"
                  styleClass={`${styles['video__placeholder']} ${rounded && styles.rounded}`}
                />
                <button
                  className={styles['video__placeholder--play-button']}
                  title="Play Video"
                  aria-disabled="false"
                >
                  <span aria-hidden="true" className={styles['vjs-icon-placeholder']}></span>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
