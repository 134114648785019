import { AttachmentActionContainerBaseProps } from '@integration-frontends/integration/ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assetEntitySelectors, IntegrationRootState } from '@integration-frontends/integration/core/application';
import { attachmentActionClicked } from '../../../actions';
import { createNotification } from '@integration-frontends/common/notifications';
import { SmartsheetAttachmentAction } from '../../../model';
import {
  SmartsheetEventType,
  SmartsheetPostConfirmationPayload,
  subscribeToEvent,
} from '../../../common/smartsheet';
import { reactToPlacement } from '../smar-attachment-placement-details/smar-attachment-placement-details';
import { useTranslation } from 'react-i18next';
import {
  ATTACH_LOADING_LABEL_KEY,
  ATTACH_ROW_SUCCESS_LABEL_KEY,
  ATTACH_ROW_SUCCESS_NOTIFICATION_KEY,
  ATTACH_TO_ROW_LABEL_KEY,
  INTEGRATION_COMMON_NAMESPACE,
  MULTI_SELECT_ATTACH_TO_ROW_KEY,
  MULTI_SELECT_ATTACHED_TO_ROW,
  MULTI_SELECT_ATTACHING_KEY,
} from '@integration-frontends/integration/ui/common/i18n';
import { ATTACH_CONFIRMATION_TIMEOUT_MS, Availability } from './constants';
import { noop } from 'lodash';
import { IconAttachToRow } from '@integration-frontends/common/ui';

export function AttachToRowActionContainer({
  selectedAttachments,
  render,
  onLoading = noop,
  onSuccess = noop,
  isMultiSelect = false,
  location = 'show-page',
}: AttachmentActionContainerBaseProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const id = 'ATTACH_TO_ROW';
  const [label, setLabel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmationPayload, setConfirmationPayload] =
    useState<SmartsheetPostConfirmationPayload>(null);
  const success = !!confirmationPayload;
  const Icon = IconAttachToRow;
  const asset = useSelector((state: IntegrationRootState) =>
    assetEntitySelectors.selectById(state, selectedAttachments[0]?.assetId),
  );

  function initialize() {
    setLoading(false);
    setConfirmationPayload(null);
  }

  useEffect(() => initialize(), []);

  useEffect(() => {
    if (loading) {
      setLabel(getLoadingLabel());
    } else if (confirmationPayload) {
      setLabel(getSuccessLabel());
    } else {
      setLabel(getDefaultLabel());
    }
  }, [loading, selectedAttachments, confirmationPayload]);

  useEffect(() => {
    if (success) {
      setLoading(false);
      onSuccess(getSuccessLabel());
      const timeout = setTimeout(initialize, ATTACH_CONFIRMATION_TIMEOUT_MS);
      return () => clearTimeout(timeout);
    }
  }, [success]);

  const onClick = useCallback(() => {
    setLoading(true);
    onLoading(getLoadingLabel());

    dispatch(
      attachmentActionClicked({
        action: SmartsheetAttachmentAction.PlaceToRow,
        attachments: selectedAttachments,
      }),
    );

    subscribeToEvent(SmartsheetEventType.PostConfirmation, (payload, unsubscribe) => {
      setConfirmationPayload(payload);
      selectedAttachments.forEach((attachment) => {
        reactToPlacement(dispatch, attachment.id, attachment.assetId);
      });
      sendToastNotification(payload);
      unsubscribe();
    });
  }, [selectedAttachments]);

  function sendToastNotification(payload): void {
    const message = t(ATTACH_ROW_SUCCESS_NOTIFICATION_KEY, {
      count: selectedAttachments?.length,
      rowNumber: payload?.smarRowNumber,
    });
    dispatch(createNotification({message, location}));
  }

  function getLoadingLabel(): string {
    return isMultiSelect
      ? t(MULTI_SELECT_ATTACHING_KEY, { count: selectedAttachments?.length })
      : t(ATTACH_LOADING_LABEL_KEY);
  }

  function getSuccessLabel(): string {
    return isMultiSelect
      ? t(MULTI_SELECT_ATTACHED_TO_ROW, {
          count: selectedAttachments?.length,
          rowNumber: confirmationPayload?.smarRowNumber,
        })
      : t(ATTACH_ROW_SUCCESS_LABEL_KEY, { rowNumber: confirmationPayload?.smarRowNumber });
  }

  function getDefaultLabel(): string {
    return isMultiSelect
      ? t(MULTI_SELECT_ATTACH_TO_ROW_KEY, { count: selectedAttachments?.length })
      : t(ATTACH_TO_ROW_LABEL_KEY);
  }

  return render({
    id,
    Icon,
    label,
    loading,
    success: !!confirmationPayload,
    enabled: (asset?.availability === Availability.Published),
    onClick,
    ariaLabel: t(ATTACH_TO_ROW_LABEL_KEY, { count: selectedAttachments?.length }),
  });
}
