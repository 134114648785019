import { OrientationFiltersComponentProps } from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/components/orientation-filters/orientation-filters-component';
import { useTranslation } from 'react-i18next';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';
import { AttachmentOrientation } from '@integration-frontends/integration/core/model';
import {
  HORIZONTAL_KEY,
  ORIENTATION_KEY,
  PANORAMIC_KEY,
  SQUARE_KEY,
  VERTICAL_KEY,
} from '@integration-frontends/integration/ui/attachment-selector/i18n';
import { BFCheckbox } from '@integration-frontends/common/ui';
import React from 'react';
import { FormSection, FormSectionContent } from '../../../common/form';

export function SmarOrientationFiltersComponent({
  searchParams,
  onChange,
}: OrientationFiltersComponentProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  function isChecked(orientation: AttachmentOrientation): boolean {
    return searchParams?.orientationFilters?.includes(orientation) || false;
  }

  return (
    <FormSection expandable={true} initialExpanded={false} title={t(ORIENTATION_KEY)}>
      <FormSectionContent>
        <div className="flex flex-col">
          <div className="flex justify-between">
            <BFCheckbox
              checked={isChecked(AttachmentOrientation.Horizontal)}
              onChange={(e) => {
                onChange(AttachmentOrientation.Horizontal, e.target.checked);
              }}
              label={t(HORIZONTAL_KEY)}
            />
          </div>

          <div className="flex justify-between">
            <BFCheckbox
              checked={isChecked(AttachmentOrientation.Vertical)}
              onChange={(e) => onChange(AttachmentOrientation.Vertical, e.target.checked)}
              label={t(VERTICAL_KEY)}
            />
          </div>

          <div className="flex justify-between">
            <BFCheckbox
              checked={isChecked(AttachmentOrientation.Square)}
              onChange={(e) => onChange(AttachmentOrientation.Square, e.target.checked)}
              label={t(SQUARE_KEY)}
            />
          </div>

          <div className="flex justify-between">
            <BFCheckbox
              checked={isChecked(AttachmentOrientation.Panoramic)}
              onChange={(e) => onChange(AttachmentOrientation.Panoramic, e.target.checked)}
              label={t(PANORAMIC_KEY)}
            />
          </div>
        </div>
      </FormSectionContent>
    </FormSection>
  );
}
