import { SmartsheetAttachmentAction } from './model/attachment-actions';
import { withPayloadType } from '@integration-frontends/common/utils/redux';
import { createAction } from '@reduxjs/toolkit';
import { Attachment, AttachmentVariant } from '@integration-frontends/integration/core/model';
import { SmarPanelInitPayload, SmarPanelUploadPayload } from './common/smartsheet';

export const initPayloadReceived = createAction(
  'SMAR_INIT_PAYLOAD_RECEIVED',
  withPayloadType<{ initPayload: SmarPanelInitPayload }>(),
);
export const uploadPayloadReceived = createAction(
  'SMAR_UPLOAD_PAYLOAD_RECEIVED',
  withPayloadType<{ uploadPayload: SmarPanelUploadPayload[] }>(),
);

export const attachmentActionClicked = createAction(
  'ATTACHMENT_ACTION_CLICKED',
  withPayloadType<{
    action: SmartsheetAttachmentAction;
    attachments: (Attachment | AttachmentVariant)[];
  }>(),
);

export const tokenExpired = createAction('SMAR_TOKEN_EXPIRED');
