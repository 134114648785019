import { createReducer } from '@reduxjs/toolkit';
import { createAttachmentPlacement } from './actions';

export interface PlacementDetails {
	RowID: string,
	RowNum: number,
	RowName: string,
	SheetID: string,
	SheetName: string,
}

interface AttachmentPlacement {
  [key: string]: PlacementDetails,
}

export interface AttachmentPlacementsState {
  attachmentPlacements: AttachmentPlacement;
}

export const initialAttachmentPlacementsState: AttachmentPlacementsState = {
  attachmentPlacements: {},
};

const reducer = createReducer(initialAttachmentPlacementsState, (builder) => {
  builder
    .addCase(createAttachmentPlacement, (state, action) => {
      const placementDetails: PlacementDetails = action.payload.placementDetails;
      const attachmentID =  action.payload.attachmentID;

      state.attachmentPlacements[attachmentID] = placementDetails;
    })
});

export interface AttachmentPlacementsRootState {
  attachmentPlacements: AttachmentPlacementsState;
}

export const attachmentPlacementsRootReducer = {
  attachmentPlacements: reducer,
};
