import { TopFileTypesFiltersComponentProps } from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/components/top-file-type/top-file-types-filters-component';
import { useTranslation } from 'react-i18next';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';
import { FormSectionContent, FormSubSection } from '../../../common/form';
import { TOP_FILE_TYPES_KEY } from '@integration-frontends/integration/ui/attachment-selector/i18n';
import { BFCheckbox } from '@integration-frontends/common/ui';
import {
  CheckboxListContainer,
  CheckboxListItemContainer,
} from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/common';
import { hasFileTypeFilter } from '@integration-frontends/integration/core/model';
import React from 'react';
import '../../smar-advanced-filters.scss';

export function SmarTopFileTypesComponent({
  fileTypeAggregates,
  fileTypeToggle,
  searchParams,
}: TopFileTypesFiltersComponentProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  return (
    <FormSubSection
      id="top-file-types-filters"
      data-testid="top-file-types-filters"
      title={t(TOP_FILE_TYPES_KEY)}
    >
      <FormSectionContent>
        <CheckboxListContainer className="checkbox-list">
          {fileTypeAggregates
            .sort((agg1, agg2) => agg2.count - agg1.count)
            .slice(0, 10)
            .map((fileType) => (
              <CheckboxListItemContainer key={fileType.name}>
                <div
                  data-testid="file-type-filter"
                  className="flex cursor-pointer"
                  onClick={() => fileTypeToggle(fileType.name)}
                >
                  <div className="min-w-0 list-item">
                    <BFCheckbox
                      value={fileType.name}
                      checked={hasFileTypeFilter(searchParams, fileType.name)}
                      label={fileType.name}
                      readOnly
                    />
                  </div>
                </div>
              </CheckboxListItemContainer>
            ))}
        </CheckboxListContainer>
      </FormSectionContent>
    </FormSubSection>
  );
}
