import { createSelector } from '@reduxjs/toolkit';
import { AttachmentPlacementsRootState, AttachmentPlacementsState } from './reducer';
import { StateSelector } from '@integration-frontends/common/utils/redux';

const selectAttachmentPlacementsState: StateSelector<AttachmentPlacementsState> = (state: AttachmentPlacementsRootState) => state.attachmentPlacements;
export const selectAttachmentPlacementById = (id: string) => {
  return createSelector(selectAttachmentPlacementsState, (state: any) => {
    return state.attachmentPlacements[id];
  })
};
