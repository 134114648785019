import React, { ReactElement } from 'react';
import './panel-header-smartsheet.scss';
import { sendMessage } from '../../../../utilities';
import { IconClose, VerticalDividerLine, IconSize, BFIconButton } from '@integration-frontends/common/ui';

export interface PanelHeaderProps {
  actions: ReactElement[];
}

const panelHeaderHidden = document.URL.indexOf("hideHeader=true") !== -1;

export function PanelHeaderSmartsheet({ actions }: PanelHeaderProps) {
  return (
    !panelHeaderHidden &&
    <div className="px-lg flex items-center justify-between panel-header-container" id="panel-header-container">
      <div>
        <h1 className="brandfolder-header-text">Brandfolder</h1>
      </div>
      <div className="flex" >
        {actions.map((action, idx) => (
          <div key={idx} className="cursor-pointer flex ml-2 text-6xl text-black flex items-center">
            {action}
          </div>
        ))}
        <VerticalDividerLine height={28}/>
        <BFIconButton
          className="cursor-pointer flex mt-xxs"
          id="smar-header-close"
          onClick={() => sendMessage({ event: 'smarPanelClose'})}
          iconElement={<IconClose iconSize={IconSize.Large} />}
          style={{
            background: "none",
            margin: 0,
          }}
        />
      </div>
    </div>
  );
}
