import {
  BFAnchor,
  BFButton,
  BFDropZone,
  BFImage,
  BFLabel,
  BFSelect,
  ButtonSize,
  IconTrash,
  BFMultiSelect,
  BFToggleSwitch,
  BFInput,
  IconCaretDownSecondary,
  IconCaretRightSecondary,
  IconInfo,
} from '@integration-frontends/common/ui';
import { getExt } from '@integration-frontends/common/utils/path';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  addAssets,
  containerSelectors,
  collectionEntitySelectors,
  organizationEntitySelectors,
  removeAsset,
  selectContainer,
  selectOrganization,
  selectSection,
  upload,
  uploadAssetsSelectors,
  containerTagEntitySelectors,
  UPLOAD_ASSETS_OPTIONS_TOKEN,
  selectCollection,
  IUploadAssetsOptions,
  TagsOrigin,
} from '@integration-frontends/integration/core/application';
import { selectName } from '@integration-frontends/common/app';
import {
  Brandfolder,
  BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  Collection,
  Container,
  IBrandfolderWebsiteService,
  Organization,
  Section,
} from '@integration-frontends/integration/core/model';
import { isEmpty, prop, sortBy } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ActionsFlexContainer, ContentBody } from './common';
import {
  DESCRIPTION_KEY,
  ORGANIZATION_KEY,
  SECTION_KEY,
  SELECT_BRANDFOLDER_KEY,
  SELECT_COLLECTION_KEY,
  SELECT_ORGANIZATION_KEY,
  SELECT_SECTION_KEY,
  SELECT_TAGS_KEY,
  UPLOAD_BUTTON_LABEL_KEY,
  UPLOAD_BUTTON_UPLOADING_LABEL_KEY,
  COLLECTION_OPTIONAL_KEY,
  SELECT_CUSTOM_FIELDS_KEY,
  COLUMN_DATA_KEY,
  OPTIONAL_KEY,
} from './i18n';
import { BRANDFOLDER_KEY, INTEGRATION_COMMON_NAMESPACE } from '../common/i18n';
import { NotificationsContainer } from '@integration-frontends/integration/ui';
import { CUSTOM_FIELDS_KEY, TAGS_KEY } from '../attachment-selector/i18n';
import './upload-form.scss';
import { Tooltip, useTooltip } from '@smartsheet/lodestar-core';
type Resource = Brandfolder | Collection | Organization | Section;

interface ResourceSelectProps {
  disabled?: boolean;
  id?: string;
  resources: Resource[];
  placeholder: string;
  onChange: (id: string) => void;
  value: undefined | string;
  subject: string;
}

function ResourceSelect({
  disabled = false,
  id,
  resources,
  placeholder,
  onChange,
  value,
  subject,
}: ResourceSelectProps) {
  const handleChange = (option) => {
    if (option) {
      !isEmpty(option.value) && onChange(resources.find((r) => r.id === option.value).id);
    } else {
      onChange('');
    }
  };

  return (
    <BFSelect
      id={id}
      placeholder={placeholder}
      options={sortBy(prop('name'), resources).map(({ id, name }) => ({ label: name, value: id }))}
      data-testid={`upload-form-${subject}-select`}
      disabled={disabled}
      required
      onOptionChange={(option) => handleChange(option)}
      value={value}
    />
  );
}

export function UploadForm() {
  const bfWebsiteService: IBrandfolderWebsiteService = DI_CONTAINER.get(
    BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  );
  const uploadAssetOptions: IUploadAssetsOptions = DI_CONTAINER.get(UPLOAD_ASSETS_OPTIONS_TOKEN);
  const tagsOrigin = uploadAssetOptions.tagsOrigin || TagsOrigin.internal;
  const isTagsOriginInternal = tagsOrigin === TagsOrigin.internal;

  const {
    showAddMore,
    showTags,
    showCustomFields = false,
    multipleAssetUpload = false,
  } = DI_CONTAINER.get(UPLOAD_ASSETS_OPTIONS_TOKEN);

  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCustomFields, setSelectedCustomFields] = useState([]);
  const [selectedCustomFieldsKeys, setSelectedCustomFieldsKeys] = useState(null);
  const handleMultiChangeTags = (values) => {
    setSelectedTags(values);
  };
  const dispatch = useDispatch();
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const [addMore, setAddMore] = useState(false);
  const [externalMediaName, setExternalMediaName] = useState('');
  const [externalMediaUrl, setExternalMediaUrl] = useState('');
  const [savePreferences, setSavePreferences] = useState(false);
  const [colDataOpen, setColDataOpen] = useState(true);
  const selectedOrganization = useSelector(uploadAssetsSelectors.selectedOrganization);
  const selectedContainer = useSelector(uploadAssetsSelectors.selectedContainer);
  const selectedSection = useSelector(uploadAssetsSelectors.selectedSection);
  const selectedCollection = useSelector(uploadAssetsSelectors.selectedCollection);
  const assets = useSelector(uploadAssetsSelectors.assets);
  const uploading = useSelector(uploadAssetsSelectors.uploading);
  const organizations = useSelector(organizationEntitySelectors.selectAll);
  const brandfolders = useSelector(containerSelectors.brandfolders(selectedOrganization?.id));
  const sections = useSelector(containerSelectors.sections(selectedContainer));
  const collections = useSelector(collectionEntitySelectors.byBrandfolderId(selectedContainer?.id));
  const externalMedia = selectedSection?.assetType === 'ExternalMedium';
  const tags = useSelector(containerTagEntitySelectors.selectAll);
  const externalTags = useSelector(uploadAssetsSelectors.tagsData);
  const customFields = useSelector(uploadAssetsSelectors.customFieldsData);
  const showColData = !!externalTags && !!customFields && isTagsOriginInternal;
  const { tooltipProps: customFieldsToolTipProps, targetProps: customFieldsToolTipTargetProps } =
    useTooltip({
      placement: 'auto',
      className: 'custom-field-tool-tip',
    });
  const { tooltipProps: tagsToolTipProps, targetProps: tagsToolTipTargetProps } = useTooltip({
    placement: 'auto',
    className: 'tags-tool-tip',
  });

  const onDrop = useCallback((acceptedFiles: File[]) => {
    dispatch(
      addAssets({
        assets: acceptedFiles.map((file) => {
          return {
            name: file.name,
            url: URL.createObjectURL(file),
          };
        }),
      }),
    );
  }, []);
  const appName = useSelector(selectName);
  const findContainer = (id): Container => {
    return brandfolders.find((r) => r.id === id);
  };

  const isUploadDisabled = (): boolean => {
    if (externalMedia) {
      return !selectedSection || !externalMediaName || !externalMediaUrl;
    } else {
      return !selectedSection;
    }
  };

  const handleMultiChangeCustomFields = (values) => {
    setSelectedCustomFieldsKeys(values);
    values.map((value) => {
      setSelectedCustomFields([
        ...selectedCustomFields,
        { [value.value]: customFields[value.value] },
      ]);
    });
  };

  return (
    <div className="flex-grow flex flex-col justify-between min-h-0 relative upload-form-container" data-testid="upload-page">
      <div className="flex-grow flex flex-col min-h-0">
        <ContentBody className="pb-xl">
          <header className="my-xl">{t(DESCRIPTION_KEY)}</header>

          <div className="flex flex-col gap-xl">
            <div className="flex flex-col">
              <BFLabel
                className="organization-label"
                htmlFor="organization-select"
              >
              <p>{t(ORGANIZATION_KEY)}</p>
              <ResourceSelect
                id="organization-select"
                resources={organizations}
                placeholder={t(SELECT_ORGANIZATION_KEY)}
                onChange={(id) => dispatch(selectOrganization({ id }))}
                value={selectedOrganization?.id}
                subject="organizations"
              />
              </BFLabel>
            </div>

            <div className="flex flex-col">
              <BFLabel
                htmlFor="brandfolder-select"
                className="brandfolder-label"
              >
                <p>{t(BRANDFOLDER_KEY)}</p>
                <ResourceSelect
                  disabled={!selectedOrganization}
                  id="brandfolder-select"
                  resources={brandfolders}
                  placeholder={t(SELECT_BRANDFOLDER_KEY)}
                  onChange={(id) => dispatch(selectContainer({ container: findContainer(id) }))}
                  value={selectedContainer?.id}
                  subject="brandfolders"
                />
              </BFLabel>
            </div>

            <div className="flex flex-col">
              <BFLabel
                className="section-label"
                htmlFor="section-select"
              >
                <p>{t(SECTION_KEY)}</p>
                <ResourceSelect
                  disabled={!selectedContainer}
                  id="section-select"
                  resources={sections}
                  placeholder={t(SELECT_SECTION_KEY)}
                  onChange={(id) => dispatch(selectSection({ id }))}
                  value={selectedSection?.id}
                  subject="sections"
                />
              </BFLabel>
            </div>
            <div className="flex flex-col">
              <BFLabel
                className="collection-label"
                htmlFor="collection-select"
              >
                <p>{t(COLLECTION_OPTIONAL_KEY)}</p>
                <ResourceSelect
                  disabled={!selectedSection}
                  id="collection-select"
                  resources={collections}
                  placeholder={t(SELECT_COLLECTION_KEY)}
                  onChange={(id) => dispatch(selectCollection({ id }))}
                  value={selectedCollection?.id}
                  subject="collections"
                />
              </BFLabel>
            </div>
            {externalMedia && selectedSection && (
              <>
                <div className="flex flex-col">
                  <BFLabel htmlFor="upload-name-input">Name</BFLabel>
                  <BFInput
                    className="w-full bf-input s"
                    id="upload-name-input"
                    onChange={(e) => setExternalMediaName(e.target.value)}
                    />
                </div>
                <div className="flex flex-col">
                  <BFLabel htmlFor="upload-url-input">Media URL</BFLabel>
                  <BFInput
                    className="w-full bf-input s"
                    id="upload-url-input"
                    onChange={(e) => setExternalMediaUrl(e.target.value)}
                  />
                </div>
              </>
            )}
            {!isTagsOriginInternal && showColData && (
              <span className="whitespace-nowrap flex flex-row items-center">
                <BFLabel className="inline-block">{t(COLUMN_DATA_KEY)}&nbsp;</BFLabel>
                <p className="inline-block optional-text-light">{t(OPTIONAL_KEY)}</p>
                {colDataOpen ? (
                  <IconCaretDownSecondary
                    onClick={() => setColDataOpen(!colDataOpen)}
                    className="col-data-caret-down"
                  />
                ) : (
                  <IconCaretRightSecondary
                    onClick={() => setColDataOpen(!colDataOpen)}
                    className="col-data-caret-right"
                  />
                )}
              </span>
            )}
            {showCustomFields && customFields && colDataOpen && (
              <div className="flex flex-col">
                <span className="flex flex-row">
                  <BFLabel>{t(CUSTOM_FIELDS_KEY)}</BFLabel>
                  <IconInfo className="upload-form-icon-info" {...customFieldsToolTipTargetProps} />
                  <Tooltip className="custom-field-tool-tip" {...customFieldsToolTipProps}>
                    Use column data in your sheet as Custom Fields in Brandfolder
                  </Tooltip>
                </span>
                <BFMultiSelect
                  placeholder={t(SELECT_CUSTOM_FIELDS_KEY)}
                  options={
                    customFields
                      ? Object.keys(customFields)?.map((key) => ({
                          value: key,
                        }))
                      : []
                  }
                  selected={selectedCustomFieldsKeys}
                  onChange={handleMultiChangeCustomFields}
                  data-testid={`upload-form-custom-fields-select`}
                  disabled={!!!customFields}
                  getOptionKey={(o) => o.value}
                />
              </div>
            )}
            {showTags && tags && externalTags && colDataOpen && (
              <div className="flex flex-col">
                <span className="flex flex-row">
                  <BFLabel>{t(TAGS_KEY)}</BFLabel>
                  <IconInfo className="upload-form-icon-info" {...tagsToolTipTargetProps} />
                  <Tooltip className="tags-field-tool-tip" {...tagsToolTipProps}>
                    Use values from Dropdown List columns as tags in Brandfolder.
                  </Tooltip>
                </span>
                <BFMultiSelect
                  placeholder={t(SELECT_TAGS_KEY)}
                  options={
                    isTagsOriginInternal
                      ? sortBy(prop('id'), tags).map(({ id, name = 'no name' }) => ({
                          value: name,
                        }))
                      : externalTags?.map((key) => ({
                          value: key,
                        }))
                  }
                  selected={selectedTags}
                  onChange={handleMultiChangeTags}
                  data-testid={`upload-form-tags-select`}
                  disabled={isTagsOriginInternal ? !tags?.length : !externalTags?.length}
                  getOptionKey={(o) => o.value}
                />
              </div>
            )}
            <BFLabel
              className="remember-settings-label"
              htmlFor="remember-settings-toggle"
            >
              <BFToggleSwitch
                data-cy="remember-my-settings"
                className="mr-sm mt-xxs"
                checked={savePreferences}
                id="remember-settings-toggle"
                onChange={(e) => setSavePreferences(!savePreferences)}
                />{' '}
                <p>Remember my settings</p>
              </BFLabel>
          </div>

          {assets.length > 0 && !externalMedia && (
            <div className="flex flex-col gap-md mt-xl">
              {assets.map((asset) => (
                <div key={asset.name} className="flex justify-between items-center" data-testid={`upload-asset-${asset.name}`}>
                  <div className="flex">
                    <div
                      style={{
                        flex: '0 0 52px',
                        height: 38,
                        boxShadow: '0px 4px 10px rgba(29, 49, 99, 0.1)',
                      }}
                      className="mr-md flex items-center justify-center border-bf-white border-6"
                    >
                      <BFImage
                        className="w-full h-full"
                        src={asset.url}
                        fallbackUrl={bfWebsiteService.getFileThumbnailUrl(getExt(asset.name))}
                        alt={asset.name}
                      />
                    </div>
                    <span className="flex items-center">{asset.name}</span>
                  </div>

                  <IconTrash
                    className="cursor-pointer"
                    onClick={() => dispatch(removeAsset({ asset }))}
                  />
                </div>
              ))}
            </div>
          )}

          {showAddMore && assets.length > 0 && !addMore && !externalMedia && (
            <BFAnchor className="mb-xl mt-md block" role="button" onClick={() => setAddMore(true)}>
              + Add more assets
            </BFAnchor>
          )}

          {!externalMedia && showAddMore && selectedSection && (assets.length === 0 || addMore) && (
            <BFDropZone className="mt-xxl" onDrop={onDrop} />
          )}
        </ContentBody>
      </div>

      {(assets.length > 0 || (externalMedia && !isUploadDisabled())) && (
        <ActionsFlexContainer>
          <div className="h-full w-full flex justify-center items-center">
            <BFButton
              size={ButtonSize.Small}
              disabled={isUploadDisabled()}
              className={isUploadDisabled() && 'disabled'}
              data-testid="upload-button"
              onClick={() =>
                multipleAssetUpload
                  ? assets.map((asset) => {
                      dispatch(
                        upload({
                          container: selectedCollection || selectedContainer,
                          sectionId: selectedSection.id,
                          organizationId: selectedOrganization.id,
                          assets: [asset],
                          source: `${appName}-panel`,
                          savePreferences: savePreferences,
                          externalMedia: {
                            url: externalMediaUrl,
                            name: externalMediaName,
                          },
                          tags: selectedTags.map((tag) => tag.value),
                          customFields: selectedCustomFields,
                        }),
                      );
                    })
                  : dispatch(
                      upload({
                        container: selectedCollection || selectedContainer,
                        sectionId: selectedSection.id,
                        organizationId: selectedOrganization.id,
                        assets: assets,
                        source: `${appName}-panel`,
                        savePreferences: savePreferences,
                        externalMedia: {
                          url: externalMediaUrl,
                          name: externalMediaName,
                        },
                        tags: selectedTags.map((tag) => tag.value),
                        customFields: selectedCustomFields,
                      }),
                    )
              }
            >
              {uploading
                ? t(UPLOAD_BUTTON_UPLOADING_LABEL_KEY)
                : t(UPLOAD_BUTTON_LABEL_KEY, { count: assets.length || 1 })}
            </BFButton>
          </div>
        </ActionsFlexContainer>
      )}
      <NotificationsContainer location="uploadForm" placement="above" bottom={75} timer={3000} />
    </div>
  );
}

function onUpload(container, sectionId) {}
