import { useTranslation } from 'react-i18next';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';
import {
  AttachmentUploadDate,
  getUploadEndDate,
  getUploadStartDate,
} from '@integration-frontends/integration/core/model';
import {
  ALL_KEY,
  UPLOAD_DATE_KEY,
  UPLOAD_DATE_LAST_30_MINS_KEY,
  UPLOAD_DATE_PAST_24_HOURS_KEY,
  UPLOAD_DATE_PAST_7_DAYS_KEY,
  UPLOAD_DATE_RANGE_KEY,
} from '@integration-frontends/integration/ui/attachment-selector/i18n';
import { BFRadio } from '@integration-frontends/common/ui';
import React, { useCallback } from 'react';
import { UploadDateFilterComponentProps } from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/components/upload-date-filter/upload-date-filter-component';
import { FormGroupVertical, FormSection, FormSectionContent } from '../../../common/form';
import { DatePickerDate, DateRangePickerInput } from '@smartsheet/lodestar-core';
import './smar-upload-date-filter-component.scss';
import { format } from 'date-fns';

export function SmarUploadDateFilterComponent({
  searchParams,
  onSelect,
  onDateRangeChange,
}: UploadDateFilterComponentProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  const uploadStartDate = getUploadStartDate(searchParams);
  const uploadEndDate = getUploadEndDate(searchParams);
  const datePickerUploadStartDate: DatePickerDate = uploadStartDate
    ? {
        year: uploadStartDate.getFullYear(),
        month: uploadStartDate.getMonth(),
        day: uploadStartDate.getDay() + 1,
      }
    : null;
  const datePickerUploadEndDate: DatePickerDate = uploadEndDate
    ? {
        year: uploadEndDate.getFullYear(),
        month: uploadEndDate.getMonth(),
        day: uploadEndDate.getDay() + 1,
      }
    : null;

  function isChecked(attachmentUploadDate: AttachmentUploadDate): boolean {
    return searchParams?.uploadDateFilter?.uploadDateEnum === attachmentUploadDate || false;
  }

  const dateRangeChange = useCallback((start: DatePickerDate, end: DatePickerDate) => {
    onDateRangeChange(
      format(new Date(start.year, start.month, start.day)),
      format(new Date(end.year, end.month, end.day)),
    );
  }, [onDateRangeChange]);

  return (
    <FormSection
      className="smar-upload-date-filter-container"
      expandable={true}
      initialExpanded={!!searchParams?.uploadDateFilter}
      title={t(UPLOAD_DATE_KEY)}
    >
      <FormSectionContent className="upload-date-filter-content">
        <div className="flex items-center">
          <BFRadio
            checked={!searchParams?.uploadDateFilter}
            onChange={() => onSelect(null)}
            className="mr-xs"
            name="upload-date-filter"
            value="upload-date-filter"
            label={t(ALL_KEY)}
          />
        </div>
        <div className="flex items-center">
          <BFRadio
            value={AttachmentUploadDate.Last30Minutes}
            checked={isChecked(AttachmentUploadDate.Last30Minutes)}
            onChange={() => onSelect(AttachmentUploadDate.Last30Minutes)}
            className="mr-xs"
            name="upload-date-filter"
            label={t(UPLOAD_DATE_LAST_30_MINS_KEY)}
          />
        </div>
        <div className="flex items-center">
          <BFRadio
            value={AttachmentUploadDate.Past24Hours}
            checked={isChecked(AttachmentUploadDate.Past24Hours)}
            onChange={() => onSelect(AttachmentUploadDate.Past24Hours)}
            className="mr-xs"
            name="upload-date-filter"
            label={t(UPLOAD_DATE_PAST_24_HOURS_KEY)}
          />
        </div>
        <div className="flex items-center">
          <BFRadio
            value={AttachmentUploadDate.Past7Days}
            checked={isChecked(AttachmentUploadDate.Past7Days)}
            onChange={() => onSelect(AttachmentUploadDate.Past7Days)}
            className="mr-xs"
            name="upload-date-filter"
            label={t(UPLOAD_DATE_PAST_7_DAYS_KEY)}
          />
        </div>
        <div className="date-range-upload-container">
          <BFRadio
            value={AttachmentUploadDate.DateRange}
            checked={isChecked(AttachmentUploadDate.DateRange)}
            onChange={() => onSelect(AttachmentUploadDate.DateRange)}
            className="mr-xs"
            name="upload-date-filter"
            label={t(UPLOAD_DATE_RANGE_KEY)}
          />
          {isChecked(AttachmentUploadDate.DateRange) && (
            <DateRangePickerInput
              endValue={datePickerUploadEndDate}
              locale={'en'}
              onChange={dateRangeChange}
              startValue={datePickerUploadStartDate}
              format={'mm/dd/yyyy'}
            />
          )}
        </div>
      </FormSectionContent>
    </FormSection>
  );
}
