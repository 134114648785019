import { BFMultiSelectProps, BFMultiSelectOption } from '@integration-frontends/common/ui';
import React, {useState} from 'react';
import { MultiSelect } from '@smartsheet/lodestar-core';
import './smar-select.scss';

export function SmarMultiSelect({
  options,
  placeholder,
  selected,
  onChange,
  tabIndex,
  disabled,
  isClearable,
  ...restProps
}: BFMultiSelectProps) {


  return (
    <div {...restProps}>
      <MultiSelect
        id='multi-select'
        options={options}
        className="smar-select"
        onChange={onChange}
        value={selected}
        tabIndex={tabIndex?.toString()}
        placeholder={<span className="smar-select-placeholder">{placeholder}</span>}
        formatOptionLabel={(option, labelMeta) => {
          return <span className="smar-select-option">{option.value}</span>;
        }}
        isDisabled={disabled}
        isClearable={isClearable}
      />
    </div>
  );
}
