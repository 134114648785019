import React from 'react';
import { MenuSmartsheet } from '../menu-smartsheet';
import { PanelHeaderSmartsheet } from '../panel-header-smartsheet';

export interface HeaderProps {
  assetId?: string;
  containerId?: string;
}

export function HeaderSmartsheet({ assetId, containerId }: HeaderProps) {
  const menuAction = <MenuSmartsheet assetId={assetId} containerId={containerId} />;

  return <PanelHeaderSmartsheet actions={[menuAction]} />;
}
