import React, { HTMLAttributes } from 'react';
import expandSvg from '@integration-frontends/assets/lodestar-icons/svg/core/xxSmall/expand.svg';
import './form.scss';
import classNames from 'classnames';
import { Disclosure } from '@headlessui/react';

export function FormContainer(props: HTMLAttributes<HTMLDivElement>) {
  return <div className="form-container" {...props} />;
}

export function FormHeader(props: HTMLAttributes<HTMLDivElement>) {
  return <div className="form-header" {...props} />;
}

export type FormSectionProps = {
  title?: string;
  expandable?: boolean;
  initialExpanded?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export function FormSection({
  children,
  className,
  expandable = false,
  initialExpanded,
  title,
  ...restProps
}: FormSectionProps) {
  return (
    <Disclosure defaultOpen={initialExpanded || !expandable}>
      {({ open }) => (
        <div className={classNames('form-section', className, { expanded: open })} {...restProps}>
          {expandable && <Disclosure.Button as="div" className="toggle-expand" />}
          {title && (
            <div className={classNames('form-section-title', { expanded: open })}>
              <h3>{title}</h3>
              {expandable && (
                <img
                  src={expandSvg}
                  alt="Expand"
                  className={classNames('expand-icon', { expanded: open })}
                />
              )}
            </div>
          )}

          <Disclosure.Panel as="div">{children}</Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
}

export type FormSubSectionProps = {
  title: string;
} & HTMLAttributes<HTMLDivElement>;

export function FormSubSection({ children, title, ...restProps }: FormSubSectionProps) {
  return (
    <div className="form-subsection" {...restProps}>
      <div className="form-subsection-title">
        <h3>{title}</h3>
      </div>
      {children}
    </div>
  );
}

export function FormSectionContent({ className, ...restProps }: HTMLAttributes<HTMLDivElement>) {
  return <div className={classNames('form-section-content', className)} {...restProps} />;
}

export function FormGroupVertical(props: HTMLAttributes<HTMLDivElement>) {
  return <div className="form-group vertical" {...props} />;
}

export function FormRowVertical(props: HTMLAttributes<HTMLDivElement>) {
  return <div className="form-row vertical" {...props} />;
}

export function FormActions(props: HTMLAttributes<HTMLDivElement>) {
  return <div className="form-actions" {...props} />;
}
