import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from '@integration-frontends/common/utils/redux';

import { PlacementDetails } from './reducer';

export const createAttachmentPlacement = createAction(
  'CREATE_ATTACHMENT_PLACEMENT',
  withPayloadType<{
    attachmentID: string,
    placementDetails: PlacementDetails,
  }>()
);
