import LogRocket from 'logrocket';
import { Identity } from '@integration-frontends/common/auth/core/model';
import { sendLogRocketIdentity } from '@apps-common/utils';

let logRocketInitialized: boolean = false;
if (window.location.toString().includes('https://integration-smartsheet.brandfolder-svc.com/')) {
  LogRocket.init('3xwicr/integration-smartsheet', {
    mergeIframes: true,
    console: { shouldAggregateConsoleErrors: true },
    dom: { inputSanitizer: true },
  });
  logRocketInitialized = true;
}

export function identifyLogRocket(identity: Identity) {
  if (logRocketInitialized) {
    sendLogRocketIdentity(identity);
  }
}
