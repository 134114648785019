import { uploadPayloadReceived } from '../../actions';
import { call } from 'typed-redux-saga';
import {
  addAssets,
  AssetData,
  uploadAssetsInit,
} from '@integration-frontends/integration/core/application';
import { SmarPanelUploadPayload } from '../../common/smartsheet';
import { push } from 'redux-first-history';
import { DI_CONTAINER, STORE_TOKEN } from '@integration-frontends/core';

export async function goToUploadPageWithAsset(payload: SmarPanelUploadPayload[]) {
  const isDateColType = (colName: string) => {
    return colName.toLowerCase().includes('date');
  };

  const localizeDateTimestamp = (value: string) => {
    const intTimestamp = Number(value);
    const isValidDate = (new Date(intTimestamp).toLocaleDateString() !== "Invalid Date");
    return isValidDate ? new Date(intTimestamp).toLocaleDateString() : value;
  };

  const uploadData = payload.reduce((uploadDataKeyVals, asset) => {
    const keys = Object.keys(asset.smarRowData || {});
    keys.forEach((key) => {
      let input = asset.smarRowData[key];
      let output: string | string[];
      input = isDateColType(key) ? localizeDateTimestamp(input as string) : input;
      try {
        output = JSON.parse(input)
      } catch {
        output = input as string;
      }
      
      if (!uploadDataKeyVals[key] && output !== '') {
        uploadDataKeyVals[key] = Array.isArray(output) ? output : [output];        
      } else if (uploadDataKeyVals[key] && output !== '') { 
        if (Array.isArray(output)) {
          output.forEach((value) => {
            if (!uploadDataKeyVals[key].includes(value)) {
              uploadDataKeyVals[key].push(value);
            } 
          }); 
        } else {
          if (!uploadDataKeyVals[key].includes(output)) {
            uploadDataKeyVals[key].push(output)
          }
        }
      }
    })
    return uploadDataKeyVals;
  }, {});
  
  const keys = Object.keys(uploadData);
  const tags = keys.reduce((tags: string[], key) => {
    uploadData[key].forEach((tag: string) => {
      if (!tags.includes(tag) && tag !== '') {
        tags.push(tag);
      }
    });
    return tags;
  }, []);

  const assets: AssetData[] = payload.map((asset) => {
    return {
      name: asset.file_name,
      url: URL.createObjectURL(asset.file),
    };
  });

  const store = DI_CONTAINER.get(STORE_TOKEN);
  store.dispatch(addAssets({ assets }));
  store.dispatch(uploadAssetsInit({ customFields: uploadData, tags: tags }));
  store.dispatch(push(`/upload`));
}

export function* uploadAsset(action: ReturnType<typeof uploadPayloadReceived>) {
  const { uploadPayload } = action.payload;
  yield call(goToUploadPageWithAsset, uploadPayload);
}
