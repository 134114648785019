import { call, put } from 'typed-redux-saga';
import { push } from 'redux-first-history';
import { getPersistedRoute } from '@integration-frontends/apps/common/routing/route-persistence';

export const DEFAULT_ROUTE = '/select-attachment';

export function* defaultNavigate() {
  const persistedRoute = yield call(getPersistedRoute);
  yield put(push(persistedRoute || DEFAULT_ROUTE));
}
