import { BFStorage, STORAGE_TOKEN } from '../../../../../libs/core/src/lib/storage';
import { DI_CONTAINER } from '@integration-frontends/core';
import { call, takeEvery } from 'typed-redux-saga';
import { LOCATION_CHANGE } from 'redux-first-history';
import { PERSISTED_ROUTE_KEY } from './common';
import { locationChangeAction } from 'redux-first-history/build/es6/actions';

export function createPersistRouteEffect(routeMatches: RegExp[] = []) {
  function* handler(action: ReturnType<typeof locationChangeAction>) {
    const storage: BFStorage = DI_CONTAINER.get(STORAGE_TOKEN);
    const { pathname, search } = action.payload.location;

    for (const idx in routeMatches) {
      if (routeMatches[idx].test(pathname)) {
        console.log(`Persisting route: ${pathname}${search}`);
        yield call(storage.setItem, PERSISTED_ROUTE_KEY, JSON.stringify(action.payload.location));
      }
    }
  }

  return function* () {
    yield takeEvery(LOCATION_CHANGE, handler);
  };
}
