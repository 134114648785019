import { getExtensionMediaType, MediaType } from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';
import { MediaTypeSupportServiceBlacklistStrategy } from '@integration-frontends/integration/infrastructure/isomorphic';

const BLACKLISTED_FILE_EXTENSIONS = [
  'ade',
  'adp',
  'apk',
  'app',
  'bat',
  'chm',
  'cmd',
  'com',
  'cpl',
  'dmg',
  'exe',
  'hta',
  'ins',
  'isp',
  'js',
  'jse',
  'jsp',
  'lnk',
  'mde',
  'msc',
  'msi',
  'msp',
  'mst',
  'pif',
  'pkg',
  'ryk',
  'scr',
  'sct',
  'sh',
  'shb',
  'vb',
  'vbe',
  'vbs',
  'wsc',
  'wsf',
  'wsh',
];

@injectable()
export class MediaTypeSupportServiceSmartsheetStrategy extends MediaTypeSupportServiceBlacklistStrategy {
  constructor(exportableMediaTypes: MediaType[]) {
    super(exportableMediaTypes, BLACKLISTED_FILE_EXTENSIONS.map(getExtensionMediaType));
  }
}
