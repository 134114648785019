import { useTranslation } from 'react-i18next';
import {
  INTEGRATION_COMMON_NAMESPACE,
  SEARCH_TERMS_KEY,
} from '@integration-frontends/integration/ui/common/i18n';
import {
  SKU_SEARCH_KEY,
  TERMS_KEY,
} from '@integration-frontends/integration/ui/attachment-selector/i18n';
import { BFLabel, BFSearchInput } from '@integration-frontends/common/ui';
import React from 'react';
import { SkuFilterComponentProps } from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/components/sku-filter/sku-filter-component';
import { FormSection, FormSectionContent } from '../../../common/form';

export function SmarSkuFilterComponent({ searchParams, onChange }: SkuFilterComponentProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  return (
    <FormSection
      expandable={true}
      initialExpanded={searchParams?.skuFilters?.length > 0}
      title={t(SKU_SEARCH_KEY)}
    >
      <FormSectionContent>
        <BFLabel>{t(TERMS_KEY)}</BFLabel>
        <BFSearchInput
          placeholder={t(SEARCH_TERMS_KEY)}
          className="w-full"
          onChange={(e) => onChange(e.target.value)}
        />
      </FormSectionContent>
    </FormSection>
  );
}
