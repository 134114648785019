import React, { useCallback, useEffect, useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './smar-attachment-placement-details.scss';
import {
  SmartsheetEventType,
  SmartsheetAttachmentDetailsPayload,
  SmartsheetCommandType,
  subscribeToEvent,
  sendCommand,
} from '../../../common/smartsheet';
import { createAttachmentPlacement } from '../../../attachment-placements/src/actions';
import { selectAttachmentPlacementById } from '../../../attachment-placements/src/selectors';
import { Tooltip, TooltipBody, MultiLineTooltipTitle, MultiLineTooltipSubtext, useTooltip, FlyoutPlacement } from '@smartsheet/lodestar-core';
import { IconAttachToSheetBlue, IconSize } from '@integration-frontends/common/ui';

interface AttachmentPlacementDetailsProps {
  attachmentID: string,
  assetID: string,
  icon?: boolean,
}

export const reactToPlacement = (dispatch, attachmentID, assetID) => {
  subscribeToEvent(SmartsheetEventType.AttachmentDetails, (payload, unsubscribe) => {
    const {
      AttachmentID,
      RowID,
      RowNum,
      RowName,
      SheetID,
      SheetName,
    } = payload;
    if (AttachmentID !== attachmentID) {
      return;
    }
    if (RowNum || SheetName) {
      dispatch(
        createAttachmentPlacement({
          attachmentID: attachmentID,
          placementDetails: {
            RowID,
            RowNum,
            RowName,
            SheetID,
            SheetName,
          },
        }),
      );
    }
    unsubscribe();
  });
  sendCommand(SmartsheetCommandType.GetPlacedAttachmentDetails, {
    assetID,
    attachmentID,
  });
};

export function SmarAttachmentPlacementDetails({ attachmentID, assetID, icon = false }: AttachmentPlacementDetailsProps) {
  const dispatch = useDispatch();
  const placementDetails = useSelector(selectAttachmentPlacementById(attachmentID));
  const {RowName, RowNum, RowID, SheetID, SheetName} = placementDetails || {};
  const { tooltipProps, targetProps } = useTooltip({placement: 'top'});
  useLayoutEffect(() => reactToPlacement(dispatch, attachmentID, assetID), []);

  return (
    <>
      {SheetID && (icon ? (
        <div data-testid="placement-tooltip">
          <IconAttachToSheetBlue className="placement-tooltip-icon ml-xxs" iconSize={IconSize.Small} {...targetProps} />
          <Tooltip {...tooltipProps} >
          {RowID ? (
            <>
              <MultiLineTooltipTitle>Attached to: {SheetName}</MultiLineTooltipTitle>
              <MultiLineTooltipSubtext>
                Row {RowNum}: {RowName}
              </MultiLineTooltipSubtext>
            </>
          ) : (
            <TooltipBody>Attached to: {SheetName}</TooltipBody>
          )}
          </Tooltip>
        </div>
      ) : (
        <div data-testid="placement-tooltip">
          <div>
            <span {...targetProps} className="placement-details">{RowID ? `Row ${RowNum}: ${RowName}` : `Sheet: ${SheetName}`}</span>
          </div>
          {RowID && (
            <Tooltip {...tooltipProps} >
              <TooltipBody>Go to row #{RowNum}</TooltipBody>
            </Tooltip>
          )}
        </div>

      ))}
    </>
  );
}
