import {
  FiltersDisplayProps,
  orientationFiltersString,
  uploadDateFilterString,
} from '@integration-frontends/integration/ui/attachment-selector/components/show-page';
import React from 'react';
import {
  hasAssetStatusFilter,
  hasCustomFieldFilters,
  hasFileTypeFilters,
  hasFilters,
  hasQueryFilter,
  hasSkuFilters,
  hasTagFilters,
  removeLabelFilter,
  SearchParameters,
} from '@integration-frontends/integration/core/model';
import { useTranslation } from 'react-i18next';
import {
  getAssetStatusKey,
  INTEGRATION_COMMON_NAMESPACE,
} from '@integration-frontends/integration/ui/common/i18n';
import './smar-filters-display.scss';
import {
  CLEAR_SEARCH_KEY,
  FILTERED_BY_KEY,
  LABEL_KEY,
  PINNED_SEARCH_KEY,
  QUERY_KEY,
  SEARCHING_KEY,
  SKU_KEY,
  STATUS_KEY,
  TAG_KEY,
  TYPE_KEY,
} from '@integration-frontends/integration/ui/attachment-selector/i18n';
import { compose, filter, join, juxt, mergeRight } from 'ramda';
import { BFAnchor } from '@integration-frontends/common/ui';
import i18next from 'i18next';

function getAdvancedFilters(searchParams: SearchParameters): SearchParameters {
  const removeQuery = (params: SearchParameters) => mergeRight(params, { query: null });
  return compose(removeQuery, removeLabelFilter)(searchParams);
}

function buildQueryString(searchParams: SearchParameters): string {
  return `"${searchParams?.query}"`;
}

function buildAdvancedFilterString(searchParams: SearchParameters): string {
  return buildFiltersString(getAdvancedFilters(searchParams));
}

function buildFilterString(type: string, value: string): string {
  return `${value} (${type.toLowerCase()})`;
}

function assetStatusFiltersString(searchParams: SearchParameters): string {
  return (
    hasAssetStatusFilter(searchParams) &&
    buildFilterString(
      i18next.t(
        `${INTEGRATION_COMMON_NAMESPACE}:${getAssetStatusKey(searchParams.assetStatusFilter)}`,
      ),
      i18next.t(`${INTEGRATION_COMMON_NAMESPACE}:${STATUS_KEY}`),
    )
  );
}

function customFieldFiltersString(searchParams: SearchParameters): string {
  return (
    hasCustomFieldFilters(searchParams) &&
    Object.values(searchParams.customFieldFilters)
      .map((customFieldFilter) =>
        buildFilterString(customFieldFilter.customField.name, customFieldFilter.value),
      )
      .join(', ')
  );
}

function fileTypeFilterString(searchParams: SearchParameters): string {
  return (
    hasFileTypeFilters(searchParams) &&
    searchParams.fileTypeFilters.fileTypes
      .map((fileType) =>
        buildFilterString(
          i18next.t(`${INTEGRATION_COMMON_NAMESPACE}:${TYPE_KEY}`),
          fileType.toUpperCase(),
        ),
      )
      .join(', ')
  );
}

function queryFilterString(searchParams: SearchParameters): string {
  if (!hasQueryFilter(searchParams)) return null;
  return `${i18next.t(`${INTEGRATION_COMMON_NAMESPACE}:${QUERY_KEY}`)}(${searchParams.query})`;
}

function skuFiltersString(searchParams: SearchParameters): string {
  return (
    hasSkuFilters(searchParams) &&
    searchParams.skuFilters
      .map((skuFilter) =>
        buildFilterString(i18next.t(`${INTEGRATION_COMMON_NAMESPACE}:${SKU_KEY}`), skuFilter),
      )
      .join(', ')
  );
}

function tagFiltersString(searchParams: SearchParameters): string {
  return (
    hasTagFilters(searchParams) &&
    searchParams.tagFilters.tags
      .map((tag) =>
        buildFilterString(i18next.t(`${INTEGRATION_COMMON_NAMESPACE}:${TAG_KEY}`), tag.name),
      )
      .join(', ')
  );
}

function labelFilterString({ labelFilter }: SearchParameters): string {
  return (
    labelFilter &&
    buildFilterString(i18next.t(`${INTEGRATION_COMMON_NAMESPACE}:${LABEL_KEY}`), labelFilter.name)
  );
}

function pinnedSearchFilterString({ pinnedSearch }: SearchParameters): string {
  return (
    pinnedSearch &&
    buildFilterString(
      i18next.t(`${INTEGRATION_COMMON_NAMESPACE}:${PINNED_SEARCH_KEY}`),
      pinnedSearch.label,
    )
  );
}

export function buildFiltersString(searchParams: SearchParameters): string {
  return compose(
    join(', '),
    filter(Boolean),
    juxt([
      queryFilterString,
      tagFiltersString,
      customFieldFiltersString,
      fileTypeFilterString,
      uploadDateFilterString,
      assetStatusFiltersString,
      orientationFiltersString,
      skuFiltersString,
      labelFilterString,
      pinnedSearchFilterString,
    ]),
  )(searchParams);
}

function hasOnlyQueryFilter(searchParams: SearchParameters): boolean {
  return hasQueryFilter(searchParams) && !hasFilters(getAdvancedFilters(searchParams));
}

export function SmarFiltersDisplay({ searchParams, onClear }: FiltersDisplayProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  const ClearSearchButton = () => (
    <BFAnchor className="font-medium" data-cy="clear-filter-action" onClick={onClear}>
      {t(CLEAR_SEARCH_KEY)}
    </BFAnchor>
  );

  return hasFilters(searchParams) ? (
    <span className="smar-filters-display" data-testid="filter-display">
      {hasQueryFilter(searchParams) && (
        <div>
          <span className="font-medium">{t(SEARCHING_KEY)}:&nbsp;</span>
          <span className="font-medium">{buildQueryString(searchParams)}</span>
          {hasOnlyQueryFilter(searchParams) && (
            <span className="only-query-clear-btn">
              <ClearSearchButton />
            </span>
          )}
        </div>
      )}

      {hasFilters(getAdvancedFilters(searchParams)) && (
        <div>
          <p className="font-medium">
            {t(FILTERED_BY_KEY)}:&nbsp;
            <span>{buildAdvancedFilterString(searchParams)}</span>
          </p>
        </div>
      )}

      {!hasOnlyQueryFilter(searchParams) && <ClearSearchButton />}
    </span>
  ) : null;
}
