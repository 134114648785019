import { getParam, sendMessage } from '../../common/utilities';

export function emitInitMessage() {
  const initMsg = getParam('initMsg');
  initMsg && sendMessage({ event: 'initMsg', payload: initMsg });
}

export function emitAuthMessage() {
  sendMessage({ event: 'smarPanelAuth' });
}
