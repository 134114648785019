import { initPayloadReceived } from '../../actions';
import { call } from 'typed-redux-saga';
import { SmarPanelInitPayload } from '../../common/smartsheet';
import {
  ATTACHMENT_REPO_TOKEN,
  IAttachmentRepo,
} from '@integration-frontends/integration/core/model';
import { BFHistory, BFStore, DI_CONTAINER, HISTORY_TOKEN, STORE_TOKEN } from '@integration-frontends/core';
import { push } from 'redux-first-history';
import { getPersistedRoute } from '@integration-frontends/apps/common/routing/route-persistence';

export async function goToSpecifiedRoute(payload: SmarPanelInitPayload) {
  const store = DI_CONTAINER.get<BFStore>(STORE_TOKEN);
  const history = DI_CONTAINER.get<BFHistory>(HISTORY_TOKEN);
  const attachmentRepo: IAttachmentRepo = DI_CONTAINER.get(ATTACHMENT_REPO_TOKEN);

  if (payload.attachmentId) {
    const attachment = await attachmentRepo.getAttachment(payload.attachmentId);
    store.dispatch(
      push(
        `/select-attachment/asset-details/${attachment.assetId}?selectedAttachmentId=${payload.attachmentId}`,
      ),
    );
  } else if (!history.location.pathname.match(/\/select-attachment(\/.*)+/g)) {
    // only navigate if user is on a top level route
    const persistedRoute = await getPersistedRoute();
    if (persistedRoute) {
      store.dispatch(push(persistedRoute));
    } else {
      store.dispatch(push(`/select-attachment`));
    }
  }
}

export function* initPayloadNavigate(action: ReturnType<typeof initPayloadReceived>) {
  const smarInitPayload = action.payload.initPayload;
  yield call(goToSpecifiedRoute, smarInitPayload);
}
