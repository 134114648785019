import {
  AttachmentActionsContainerProps,
  DefaultAttachmentActionsContainer,
} from '@integration-frontends/integration/ui/attachment-selector/common/attachment-actions/attachment-actions-container';
import React from 'react';
import './smar-attachment-actions-container.scss';

export function SmarAttachmentActionsContainer(props: AttachmentActionsContainerProps) {
  return <DefaultAttachmentActionsContainer {...props} />;
}
