import styles from './OptImg.module.scss';
import React, { FunctionComponent } from 'react';
// Add experimental (Chromium-only) HTML attributes for link elements
declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    imagesizes?: string;
    imagesrcset?: string;
  }
}

interface OptImgProps {
  aboveFold?: boolean;
  alt: string;
  nativeHeight: string;
  nativeWidth: string;
  src: string;
  widthOption: 'full' | 'half' | 'custom';
  containerStyle?: React.CSSProperties;
  customSizes?: string;
  hero?: boolean;
  imgStyle?: React.CSSProperties;
  preferredFileType?: string;
  styleClass?: string;
}

export const OptImg: FunctionComponent<OptImgProps> = ({
  aboveFold = false,
  alt,
  nativeHeight,
  nativeWidth,
  // src is a Brandfolder CDN link without URL parameters, ex:  https://cdn.bfldr.com/I6FML9WY/at/63rjs8mtknf66g4k3q7qqsvk/OG-everlast
  src,
  // full: hero images, full width on all screens
  // half: full on mobile & half on wider
  // custom: provide custom sizes and srcset values
  widthOption,
  containerStyle,
  customSizes,
  hero = false,
  imgStyle,
  preferredFileType = 'jpg',
  styleClass = '',
  // https://developers.google.com/web/fundamentals/design-and-ux/responsive/images#relative_sized_images
}) => {
  const srcWithoutParams = src.split(/[?#]/)[0].split('.png')[0].split('.jpg')[0];
  // Find and pass along position= for CDN links using the position= param
  const params = new URLSearchParams(src.split(/[?#]/)[1]);
  const positionParam = params.get('position') ? `&position=${params.get('position')}` : '';

  const optSrc = `${srcWithoutParams}?format=${preferredFileType}&auto=webp${positionParam}`;

  // sizes="(min-width: 600px) 25vw, (min-width: 500px) 50vw, 100vw"
  let optSizeSet: string;
  switch (widthOption) {
    case 'full':
      optSizeSet = '(min-width: 1600px) 1600px, 100vw';
      break;
    case 'half':
      optSizeSet = '(min-width: 1600px) 800px, (min-width: 580px) 50vw, 100vw';
      break;
    case 'custom':
      optSizeSet = customSizes;
      break;
  }

  // srcset="100.png 100w, 200.png 200w, 400.png 400w, 800.png 800w, 1600.png 1600w, 2000.png 2000w"
  const sizes = [200, 400, 600, 900, 1600, 2000, 2600, 3200];
  const srcSet = sizes
    .map((pixelWidth) => `${optSrc}&width=${pixelWidth} ${pixelWidth}w`)
    .join(',');
  const optSrcSet = srcSet;

  const calculatedAspectRatio = `${(parseInt(nativeHeight) / parseInt(nativeWidth)) * 100}%`;

  const aspectRatioStyles = {
    height: '0',
    paddingBottom: calculatedAspectRatio,
    aspectRatio: `${nativeWidth} / ${nativeHeight}`,
  };

  return (
    <>
      <div className={`${styleClass} ${styles['image__container']}`} style={containerStyle}>
        <div className={styles['image__container--inner']} style={aspectRatioStyles}>
          <img
            alt={alt}
            className={`${styles['image__image']}`}
            loading={hero ? 'eager' : 'lazy'}
            sizes={optSizeSet}
            src={optSrc}
            srcSet={optSrcSet}
            style={imgStyle}
          />
        </div>
      </div>
    </>
  );
};
