import { BFRadioProps } from '@integration-frontends/common/ui';
import { Radio } from '@smartsheet/lodestar-core';
import React from 'react';
import { noop } from 'lodash';
import './smar-radio.scss';

export function SmarRadio({ checked, label, name, onChange, ...restProps }: BFRadioProps) {
  return (
    <Radio
      className="smar-radio"
      name={name}
      onChange={onChange || noop}
      isChecked={checked}
      label={label}
      {...restProps}
    />
  );
}
