import { uploadAssetsSelectors } from '@integration-frontends/integration/core/application';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { INTEGRATION_COMMON_NAMESPACE } from '../common/i18n';
import { PageContainer, PageScrollContainer } from '../common/layout/page';
import { IntegrationPageLoader } from '../common/page-loader/page-loader';
import { TitleFlexContainer } from './common';
import { TITLE_KEY } from './i18n';
import { UploadForm } from './upload-form';
import { UploadSuccess } from './upload-success';

export function AttachmentUploader() {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const success = useSelector(uploadAssetsSelectors.success);
  const loading = useSelector(uploadAssetsSelectors.loading);

  return (
    <PageContainer>
      <PageScrollContainer className="h-full">
        <div className="flex-grow flex flex-col min-h-0">
          {loading ? (
            <IntegrationPageLoader />
          ) : (
            <>
              <TitleFlexContainer>{t(TITLE_KEY)}</TitleFlexContainer>
              {success ? <UploadSuccess /> : <UploadForm />}
            </>
          )}
        </div>
      </PageScrollContainer>
    </PageContainer>
  );
}
