import { AssetDetailsComponentProps } from '@integration-frontends/integration/ui/attachment-selector/components/asset-details-page/asset-details/asset-details-component';
import { useTranslation } from 'react-i18next';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';
import { useAttachmentActions } from '@integration-frontends/integration/ui/attachment-selector/common/attachment-actions/use-attachment-action';
import {
  PageSection,
  PageSectionContent,
} from '@integration-frontends/integration/ui/common/layout/page';
import { AttachmentActionsContainer } from '@integration-frontends/integration/ui/attachment-selector/common/attachment-actions/attachment-actions-container';
import {
  CREATED_ON_KEY,
  EXPIRES_ON_KEY,
  LAST_UPDATED_ON_KEY,
} from '@integration-frontends/integration/ui/attachment-selector/i18n';
import { format } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from '@integration-frontends/core';
import React from 'react';
import { FlexSpacer } from '@integration-frontends/integration/ui/attachment-selector/common/layout/flex-spacer';
import './smar-asset-details-component.scss';
import { NotificationsContainer } from '@integration-frontends/integration/ui';

export function SmarAssetDetailsComponent({ asset, attachment }: AssetDetailsComponentProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const { attachmentActionContainers } = useAttachmentActions();

  return (
    <PageSection className="smar-asset-details relative">
      <NotificationsContainer location="asset-details" top={-70} />
      <PageSectionContent>
        <FlexSpacer size={'m'} direction={'vertical'}>
          {attachmentActionContainers.length > 0 && (
            <div>
              <AttachmentActionsContainer location="asset-details" attachments={[attachment]} />
            </div>
          )}
          <div>
            <div>
              {t(LAST_UPDATED_ON_KEY)} {format(asset?.updatedAt, DEFAULT_DATE_FORMAT)}
            </div>
            <div>
              {t(CREATED_ON_KEY)} {format(asset?.createdAt, DEFAULT_DATE_FORMAT)}
            </div>
            <>
              {asset?.availabilityEnd && (
                <div>
                  {t(EXPIRES_ON_KEY)} {format(asset?.availabilityEnd, DEFAULT_DATE_FORMAT)}
                </div>
              )}
            </>
          </div>
        </FlexSpacer>
      </PageSectionContent>
    </PageSection>
  );
}
