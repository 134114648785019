import { HeaderSmartsheet } from '../header-smartsheet';
import * as React from 'react';
import { useState } from 'react';
import { AttachmentSelector } from '@integration-frontends/integration/ui';

export function SelectAttachmentSmartsheet() {
  const [containerId, setContainerId] = useState(null);
  const [assetId, setAssetId] = useState(null);

  return (
    <>
      <HeaderSmartsheet containerId={containerId} assetId={assetId} />
      <div id="attachment-selector-container" className="overflow-y-hidden h-full">
        <AttachmentSelector containerSelect={setContainerId} assetSelect={setAssetId} />
      </div>
    </>
  );
}
