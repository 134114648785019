import { useTranslation } from 'react-i18next';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';
import {
  CUSTOM_FIELDS_KEY,
  KEY_KEY,
  SELECT_KEY_KEY,
  SELECT_VALUE_KEY,
  VALUE_KEY,
} from '@integration-frontends/integration/ui/attachment-selector/i18n';
import { BFLabel, BFSelect, IconClose } from '@integration-frontends/common/ui';
import React from 'react';
import { CustomFieldsFiltersComponentProps } from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/components/custom-fields-filters/custom-fields-filters-component';
import {
  FormGroupVertical,
  FormRowVertical,
  FormSectionContent,
  FormSubSection,
} from '../../../common/form';
import { containerSelectors } from '@integration-frontends/integration/core/application';
import { useSelector } from 'react-redux';

export function SmarCustomFieldsFiltersComponent({
  customFieldFilters,
  customFields,
  onFilterRemove,
  onKeySelect,
  onValueSelect,
  selectedFieldId,
}: CustomFieldsFiltersComponentProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  return (
    <FormSubSection title={t(CUSTOM_FIELDS_KEY)}>
      <FormSectionContent>
        {customFieldFilters.length > 0 && (
          <div className="mb-md">
            {customFieldFilters.map((filter) => (
              <div key={filter.customField.id} className="flex justify-between mb-sm">
                <span>{filter.customField.name}</span>
                <span>{filter.value}</span>
                <IconClose
                  aria-label="close brandfolder"
                  className="cursor-pointer"
                  onClick={() => onFilterRemove(filter.customField.id)}
                />
              </div>
            ))}
          </div>
        )}

        <FormGroupVertical>
          <FormRowVertical>
            <BFLabel>{t(KEY_KEY)}</BFLabel>
            <BFSelect
              required
              placeholder={t(SELECT_KEY_KEY)}
              data-testid="smar-custom-fields-key-selector"
              options={customFields.map(({ id, name }) => ({ label: name, value: id }))}
              value={selectedFieldId}
              onOptionChange={(option) => onKeySelect(option?.value as string)}
              className="text-base"
            />
          </FormRowVertical>

          {selectedFieldId && (
            <SmarCustomFieldsFiltersSelect
              field={customFields.find((field) => field.id === selectedFieldId)}
              onOptionChange={(option) => onValueSelect(option?.value as string)}
            />
          )}
        </FormGroupVertical>
      </FormSectionContent>
    </FormSubSection>
  );
}

const SmarCustomFieldsFiltersSelect = ({ field, onOptionChange }) => {
  const options = useSelector(
    containerSelectors.customFieldValues.selectValuesByCustomFieldId(field.id),
  );
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  return (
    <FormRowVertical key={field.id}>
      <BFLabel>{t(VALUE_KEY)}</BFLabel>
      <BFSelect
        required
        data-testid="smar-custom-fields-value-selector"
        placeholder={t(SELECT_VALUE_KEY)}
        options={options.map((option) => ({ label: option.value, value: option.value }))}
        onOptionChange={onOptionChange}
        className="text-base"
      />
    </FormRowVertical>
  );
};
