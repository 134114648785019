import { IDENTITY_STORE_TOKEN, IIdentityStore } from '../../../common';
import { emitAuthMessage } from '../smar';
import { DI_CONTAINER } from '@integration-frontends/core';
import { call, put, race, take } from 'typed-redux-saga';
import { push } from 'redux-first-history';
import { initLoaded, initSuccess } from '@integration-frontends/common/app';
import { loginSuccess } from '@integration-frontends/common/auth/core/application';
import {
  AUTHENTICATE_SERVICE_TOKEN,
  IAuthenticateService,
} from '@integration-frontends/common/auth/core/model';
import { loginError } from '../../../../../../../libs/common/auth/core/application/src/lib/actions';
import { identifyLogRocket } from 'apps/integration-smartsheet/logrocket/logrocketSetup';
import { sendSegmentIdentifyAction } from '@integration-frontends/common/analytics';
import { setPersistedRoute } from '@integration-frontends/apps/common/routing/route-persistence';

export function* defaultAuth() {
  const authService: IAuthenticateService = DI_CONTAINER.get(AUTHENTICATE_SERVICE_TOKEN);
  const identityStore: IIdentityStore = DI_CONTAINER.get(IDENTITY_STORE_TOKEN);
  const identity = yield call(identityStore.get);

  const isAuthed = yield call(authService.isAuthenticated, identity?.token);

  if (isAuthed) {
    yield put(sendSegmentIdentifyAction({ identity }));
    yield put(initLoaded());
    identifyLogRocket(identity);
    sendSegmentIdentifyAction({ identity });
    emitAuthMessage();
    return;
  } else {
    yield call(setPersistedRoute, null);
    yield put(push('/getting-started'));
    yield put(initLoaded());

    while (true) {
      const { loggedIn } = yield race({ loggedIn: take(loginSuccess), error: take(loginError) });

      if (loggedIn) {
        yield call(identityStore.set, loggedIn.payload.identity);
        identifyLogRocket(identity);
        yield put(sendSegmentIdentifyAction({ identity: loggedIn.payload.identity }));
        emitAuthMessage();
        break;
      } else {
        yield put(push('/getting-started'));
      }
    }
  }
}
