import { put, race, take } from 'typed-redux-saga';
import { loginSuccess, loginThunk } from '@integration-frontends/common/auth/core/application';
import { loginError } from '../../../../../../../libs/common/auth/core/application/src/lib/actions';

export function* login(token: string) {
  yield put(loginThunk(token) as any);
  const {
    success,
    error,
  }: { success: ReturnType<typeof loginSuccess>; error: ReturnType<typeof loginError> } =
    yield race({
      success: take(loginSuccess),
      error: take(loginError),
    });

  return { success, error };
}
