import { SmartsheetEventType, subscribeToEvent } from '../common/smartsheet';
import { Store } from '@reduxjs/toolkit';
import { tokenExpired } from '../actions';

export function createFailureCallback(store: Store<unknown>) {
  let isListening = false;

  function createApiKeyPromise(): Promise<string> {
    return new Promise((resolve, reject) => {
      subscribeToEvent(SmartsheetEventType.PanelInitPayload, (payload, unsubscribe) => {
        isListening = false;
        resolve(payload.accessToken);
        unsubscribe();
      });
    });
  }

  return () => {
    if (!isListening) {
      isListening = true;
      store.dispatch(tokenExpired());
    }

    return createApiKeyPromise();
  };
}
