import { initPayloadReceived } from '../../actions';
import { initLoaded, initSuccess } from '@integration-frontends/common/app';
import { emitAuthMessage } from '../common/smar';
import { IDENTITY_STORE_TOKEN, IIdentityStore } from '../../common';
import { DI_CONTAINER } from '@integration-frontends/core';
import { AUTHENTICATE_SERVICE_TOKEN, IAuthenticateService } from '@integration-frontends/common/auth/core/model';
import { call, put, race, take } from 'typed-redux-saga';
import { clear, loginSuccess, loginThunk } from '@integration-frontends/common/auth/core/application';
import { PanelEventType, sendPanelEvent, SmarPanelInitPayload } from '../../common/smartsheet';
import { push } from 'redux-first-history';
import { identifyLogRocket } from '../../../../logrocket/logrocketSetup';
import { login } from '../common/auth/login';
import { sendSegmentIdentifyAction } from '@integration-frontends/common/analytics';

let retryingAuth = false;

function smartsheetCanRetryAuth(payload: SmarPanelInitPayload) {
  return !retryingAuth && payload.accessToken !== null;
}

function* smartsheetAuthRetry() {
  yield put(push('/loading'));

  // wait for another payload from SMAR
  sendPanelEvent(PanelEventType.AccessTokenExpired);
  const initPayload = yield take(initPayloadReceived);
  yield initPayloadAuth(initPayload);
}

function* waitForApiKeyLogin() {
  yield put(push('/getting-started'));
  return yield take(loginSuccess);
}

function* waitForInitPayloadLogin() {
  const initPayloadAction = yield take(initPayloadReceived);
  yield put(loginThunk(initPayloadAction.payload.initPayload?.accessToken) as never);
  return yield take(loginSuccess);
}

function* waitForLogin() {
  const identityStore: IIdentityStore = DI_CONTAINER.get(IDENTITY_STORE_TOKEN);
  const [apiSuccess, initPayloadSuccess] = yield race([
    call(waitForApiKeyLogin),
    call(waitForInitPayloadLogin),
  ]);
  const success = apiSuccess || initPayloadSuccess;
  if (success) {
    identifyLogRocket(success.payload.identity);
    yield put(sendSegmentIdentifyAction({ identity: success.payload.identity }));
    emitAuthMessage();
  }
  yield call(identityStore.set, success.payload.identity);
}

export function* initPayloadAuth(action: ReturnType<typeof initPayloadReceived>) {
  const identityStore: IIdentityStore = DI_CONTAINER.get(IDENTITY_STORE_TOKEN);
  const authService: IAuthenticateService = DI_CONTAINER.get(AUTHENTICATE_SERVICE_TOKEN);
  const smarInitPayload = action.payload.initPayload;
  const token: string = smarInitPayload?.accessToken || null;

  // default to persisted identity (api key)
  let identity = yield call(identityStore.get);

  // attempt to login using the access token
  if (smarInitPayload.accessToken !== null) {
    const { success } = yield login(token);

    // use accessToken identity or api key identity that may have been persisted in the past
    identity = success ? success.payload.identity : identity;
  }

  const isAuthed = yield call(authService.isAuthenticated, identity?.token);

  if (!isAuthed) {
    if (smartsheetCanRetryAuth(smarInitPayload)) {
      retryingAuth = true;
      yield smartsheetAuthRetry();
    } else {
      yield put(initLoaded());
      yield put(clear());
      yield waitForLogin();
    }
  } else {
    identifyLogRocket(identity);
    yield put(sendSegmentIdentifyAction({ identity }));
  }

  retryingAuth = false;
  emitAuthMessage();
  return true;
}
