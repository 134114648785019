export const BACK_KEY = 'BACK';
export const CLOSE_KEY = 'CLOSE';
export const COLLECTION_OPTIONAL_KEY = 'COLLECTION_OPTIONAL';
export const COLUMN_DATA_KEY = 'COLUMN_DATA';
export const DESCRIPTION_KEY = 'DESCRIPTION';
export const DRAG_FILES_KEY = 'DRAG_FILES';
export const NOT_AUTHORIZED_TO_UPLOAD_KEY = 'NOT_AUTHORIZED_TO_UPLOAD';
export const OPTIONAL_KEY = "OPTIONAL"
export const ORGANIZATION_KEY = 'ORGANIZATION';
export const OPEN_KEY = 'openInBrandfolder';
export const SECTION_KEY = 'SECTION';
export const SELECT_BRANDFOLDER_KEY = 'SELECT_BRANDFOLDER';
export const SELECT_COLLECTION_KEY = 'SELECT_COLLECTION';
export const SELECT_CUSTOM_FIELDS_KEY = 'SELECT_CUSTOM_FIELDS';
export const SELECT_ORGANIZATION_KEY = 'SELECT_ORGANIZATION';
export const SELECT_SECTION_KEY = 'SELECT_SECTION';
export const SELECT_TAGS_KEY = 'SELECT_TAGS';
export const TITLE_KEY = 'TITLE';
export const UPLOAD_BUTTON_LABEL_KEY = 'UPLOAD_BUTTON_LABEL';
export const UPLOAD_BUTTON_UPLOADING_LABEL_KEY = 'UPLOAD_BUTTON_UPLOADING_LABEL';
export const UPLOAD_FAILURE_KEY = 'UPLOAD_FAILURE';
export const UPLOAD_MORE_KEY = 'UPLOAD_MORE';
export const UPLOAD_SUCCESS_KEY = 'UPLOAD_SUCCESS';
export const UPLOAD_SUCCESS_KEY_BOLD1 = 'UPLOAD_SUCCESS_BOLD1';
export const UPLOAD_SUCCESS_KEY_BOLD1_plural = 'UPLOAD_SUCCESS_BOLD1_plural';
export const UPLOAD_SUCCESS_KEY_BOLD2 = 'UPLOAD_SUCCESS_BOLD2';
export const UPLOAD_SUCCESS_KEY_BOLD3 = 'UPLOAD_SUCCESS_BOLD3';
export const URL_MISSING_KEY = 'URL_MISSING';
export const VISIT_KEY = 'VISIT';
