import { BFToggleSwitchProps } from '@integration-frontends/common/ui';
import React from 'react';
import { Toggle } from '@smartsheet/lodestar-core';
import './smar-select.scss';

export function SmarToggleSwitch({
  checked,
  onChange,
  ...restProps
}: BFToggleSwitchProps) {

  return (
    <div {...restProps}>
      <Toggle
        onClick={onChange}
        isChecked={checked}
      />
    </div>
  );
}
