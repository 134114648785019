import { BFAnchor, BFButton, ButtonSize, ButtonType } from '@integration-frontends/common/ui';
import {
  backToHome,
  UPLOAD_ASSETS_OPTIONS_TOKEN,
  uploadAssetsSelectors,
  uploadMore,
} from '@integration-frontends/integration/core/application';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ContentBody } from './common';
import {
  CLOSE_KEY,
  OPEN_KEY,
  UPLOAD_MORE_KEY,
  UPLOAD_SUCCESS_KEY_BOLD1,
  UPLOAD_SUCCESS_KEY_BOLD2,
  UPLOAD_SUCCESS_KEY_BOLD3,
} from './i18n';
import { INTEGRATION_COMMON_NAMESPACE } from '../common/i18n';
import { DI_CONTAINER } from '@integration-frontends/core';
import { NotificationsContainer } from '../common/notifications/notifications';
import './upload-success.scss';
import { IconUploadAssetSuccess } from '@integration-frontends/common/ui/icons/icon-upload-asset-success';
import { sendSegmentAction } from '@integration-frontends/common/analytics';
import {
  BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  IBrandfolderWebsiteService,
} from '@integration-frontends/integration/core/model';

export function UploadSuccess() {
  const dispatch = useDispatch();
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const selectedContainer = useSelector(uploadAssetsSelectors.selectedContainer);
  const selectedSection = useSelector(uploadAssetsSelectors.selectedSection);
  const shareLink = useSelector(uploadAssetsSelectors.shareLink);
  const asset = useSelector(uploadAssetsSelectors.uploadedAsset);
  const { showAddMore } = DI_CONTAINER.get(UPLOAD_ASSETS_OPTIONS_TOKEN);
  const bfWebsiteService: IBrandfolderWebsiteService = DI_CONTAINER.get(
    BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  );

  const visitInBrandfolderClicked = useCallback(() => {
    dispatch(sendSegmentAction({ event: 'openInBrandfolder' }));
    window.open(bfWebsiteService.getAssetPageUrl(selectedContainer, asset), '_blank');
  }, [asset, selectedContainer]);

  return (
    <ContentBody
      className="upload-to-brandfolder-container"
      data-testid="asset-upload-success-container"
    >
      <div className="icon-upload-asset-success-container">
        <IconUploadAssetSuccess style={{ width: '340px', height: '190px' }} />
      </div>
      <span className="upload-to-brandfolder-title">Uploaded to Brandfolder!</span>
      <section className="upload-to-brandfolder-message-container">
        <p className="upload-to-brandfolder-message">{t(UPLOAD_SUCCESS_KEY_BOLD1)}</p>
        <p className="upload-to-brandfolder-message upload-to-brandfolder-message-bold">
          {t(UPLOAD_SUCCESS_KEY_BOLD2, {
            containerName: selectedContainer?.name,
            sectionName: selectedSection.name,
          })}
        </p>
        <p className="upload-to-brandfolder-message">{t(UPLOAD_SUCCESS_KEY_BOLD3)}</p>
      </section>
      <span className="upload-to-brandfolder-share-link-label">Share link</span>
      <div className="upload-to-brandfolder-share-link-container">
        <span
          className="upload-to-brandfolder-share-link"
          id="upload-to-brandfolder-share-link"
          data-testid="upload-to-brandfolder-share-link"
        >
          {shareLink ? shareLink : 'Unable to generate link'}
        </span>
      </div>
      <span className="upload-to-brandfolder-divider" />
      <div className="upload-to-brandfolder-footer">
        {showAddMore && (
          <BFAnchor style={{ marginBottom: 63 }} onClick={() => dispatch(uploadMore())}>
            {t(UPLOAD_MORE_KEY)}
          </BFAnchor>
        )}
        <BFButton
          size={ButtonSize.Small}
          buttonType={ButtonType.Secondary}
          onClick={() => dispatch(backToHome())}
        >
          {t(CLOSE_KEY)}
        </BFButton>
        <BFButton
          size={ButtonSize.Small}
          className="open-in-brandfolder-button"
          onClick={visitInBrandfolderClicked}
        >
          {t(OPEN_KEY, { containerName: selectedContainer?.name })}
        </BFButton>
      </div>
      <NotificationsContainer location={'upload-success'} placement="above" />
    </ContentBody>
  );
}
