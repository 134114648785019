import React from 'react';
import videojs from 'video.js';
import { useVideojs } from 'react-videojs-hook';

interface VideoPlayerProps {
  src: string;
  autoplay?: string;
  controls?: boolean;
  language?: string;
  loop?: boolean;
  muted?: boolean;
  placeholderSrc?: string;
  preload?: string;
  rounded?: boolean;
  size?: string;
  styleClass?: string;
  // for text track docs:  https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/textTracks
  textTracks?: Array<{ kind: string; src: string; srclang: string; autoShow: boolean }>;
}

export const VideoPlayer: React.FunctionComponent<VideoPlayerProps> = ({
  autoplay = 'false',
  controls = true,
  language = 'en',
  loop = false,
  muted = false,
  placeholderSrc = 'https://cdn.bfldr.com/I6FML9WY/as/7r7wnch5cg6cg8ts8f6nv/placeholder_video_poster?format=png&auto=webp&width=960&height=540',
  preload = 'auto',
  rounded = true,
  src,
  textTracks = [],
}) => {
  // Set videoJS player events, ex: onReady, onPlay, onPause, onTimeUpdate, onSeeking, onSeeked, onEnd
  // https://www.npmjs.com/package/react-videojs-hook
  // https://github.com/Hector101/react-videojs-hook/blob/master/src/useVideojs.ts

  const onPlay = (currentTime?: number) => {
    // Play text tracks if available and autoShow is true
    if (textTracks.length && textTracks[0]?.autoShow) {
      const player = videojs(vjsRef.current);
      const tracks = player.textTracks();
      for (let i = 0; i < tracks.length; i++) {
        const track = tracks[i];
        // find the captions track that's in english
        if (track.kind === 'captions' && track.language === 'en') {
          track.mode = 'showing';
        }
      }
    }
  };

  const videoOptions = {
    autoplay,
    controls,
    fluid: true,
    language,
    loop,
    muted,
    poster: placeholderSrc,
    preload,
    src,
  };

  const { vjsRef, vjsClassName } = useVideojs({
    onPlay,
    ...videoOptions,
  });

  const roundedStyle = rounded === true ? { border: '0', borderRadius: '8px' } : {};

  return (
    <div className={`videojs-react-player-loader`}>
      <div data-vjs-player style={roundedStyle}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video ref={vjsRef} className={vjsClassName} style={roundedStyle}>
          {textTracks.map((track, index) => (
            <track kind={track.kind} srcLang={track.srclang} src={track.src} key={index} />
          ))}
        </video>
      </div>
    </div>
  );
};
