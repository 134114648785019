import { PanelEventType, sendPanelEvent } from '../../../common/smartsheet';
import { take } from 'typed-redux-saga';
import { initPayloadReceived } from '../../../actions';
import { login } from './login';

export function* renewToken() {
  sendPanelEvent(PanelEventType.AccessTokenExpired);
  const initPayload: ReturnType<typeof initPayloadReceived> = yield take(initPayloadReceived);
  return yield login(initPayload.payload.initPayload?.accessToken)
}
