import {
  AttachmentActionContainerBaseProps,
  IAttachmentActionProvider,
} from '@integration-frontends/integration/ui/attachment-selector/common/attachment-actions/attachment-actions-model';
import React, { FunctionComponent } from 'react';
import { AttachToRowActionContainer } from '../ui/attachment-selector/attachment-action-containers/attach-to-row-action-container';
import { AttachToSheetActionContainer } from '../ui/attachment-selector/attachment-action-containers/attach-to-sheet-action-container';

export class AttachmentActionsProviderSmartsheetStrategy implements IAttachmentActionProvider {
  getActionContainers(): FunctionComponent<AttachmentActionContainerBaseProps>[] {
    return [AttachToRowActionContainer, AttachToSheetActionContainer];
  }
}
