import {
  Attachment,
  ATTACHMENT_REPO_TOKEN,
  AttachmentVariant,
  IAttachmentRepo,
  ResourceType,
} from '@integration-frontends/integration/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';

/**
 * Due to performance concerns, entities that are fetched as part of a "list" query do not include
 * some important data such as the CDN url. This function allows us to retrieve the missing data on
 * a specific set of attachments (such as attachments that have been selected to be imported into
 * a document.
 */
export function fetchAttachmentsDetails(
  attachments: (Attachment | AttachmentVariant)[],
): Promise<(Attachment | AttachmentVariant)[]> {
  const attachmentRepo: IAttachmentRepo = DI_CONTAINER.get(ATTACHMENT_REPO_TOKEN);

  const attachmentDetailsPromises: Promise<Attachment | AttachmentVariant>[] = attachments.map(
    (attachment) => {
      if (attachment.type === ResourceType.ATTACHMENT_VARIANT) {
        // if attachment was modified (resizing/cropping) then we can assume that it has all the required data e.g. CDN url
        return Promise.resolve(attachment);
      } else {
        // Attachment data may be incomplete depending on which API calls were used previously (due to performance concerns),
        // we may therefore need to fetch the data here to ensure its integrity
        return attachmentRepo.getAttachment(attachment.id);
      }
    },
  );

  return Promise.all(attachmentDetailsPromises);
}
