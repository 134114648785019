import React from 'react';
import { Checkbox, CheckedState } from '@smartsheet/lodestar-core';
import { BFCheckboxProps } from '@integration-frontends/common/ui';
import './smar-checkbox.scss';

export function SmarCheckbox({
  checked,
  className,
  label,
  onChange,
  onClick,
  style,
  disabled,
  ...restProps
}: BFCheckboxProps) {
  const checkedState: CheckedState = checked ? 'checked' : 'unchecked';
  return (
    <div className={className} style={style} data-testid={restProps['data-testid']}>
      <Checkbox
        className="smar-checkbox"
        label={label}
        checkedState={checkedState}
        onClick={(e) => {
          onChange && onChange(e as any);
          onClick && onClick(e as any);
        }}
        isDisabled={disabled}
      />
    </div>
  );
}
