import { Popover } from '@headlessui/react';
import {
  assetEntitySelectors,
  containerSelectors,
} from '@integration-frontends/integration/core/application';
import { logoutThunk } from '@integration-frontends/common/auth/core/application';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePopper } from 'react-popper';
import { Link } from 'react-router-dom';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  IBrandfolderWebsiteService,
} from '@integration-frontends/integration/core/model';
import { useTranslation } from 'react-i18next';
import {
  HELP_KEY,
  INTEGRATION_COMMON_NAMESPACE,
  LOG_OUT_LABEL_KEY,
  MY_BRANDFOLDERS_KEY,
  SEND_FEEDBACK_KEY,
  VISIT_IN_BRANDFOLDER_KEY,
} from '../../../../../../../../../libs/integration/ui/src/lib/common/i18n';
import { IconKebabMenu } from '@integration-frontends/common/ui';
import classNames from 'classnames';
import './menu-smartsheet.scss';

interface PanelProps {
  assetId: string;
  containerId: string;
}

function Panel({ assetId, containerId }: PanelProps) {
  const bfWebsiteService: IBrandfolderWebsiteService = DI_CONTAINER.get(
    BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  );
  const dispatch = useDispatch();
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const selectedContainer = useSelector((state) =>
    containerSelectors.selectById(state, containerId),
  );
  const selectedAsset = useSelector((state) => assetEntitySelectors.selectById(state, assetId));

  const onLogout = () => {
    dispatch(logoutThunk() as any);
  };

  return (
    <div className="menu-card-popover">
      <Link
        data-testid="my-organizations-menu-item"
        to="/select-attachment"
        className="font-medium menu-card-item"
      >
        {t(MY_BRANDFOLDERS_KEY)}
      </Link>
      {selectedContainer && (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={
            selectedAsset
              ? bfWebsiteService.getAssetPageUrl(selectedContainer, selectedAsset)
              : bfWebsiteService.getContainerUrl(selectedContainer)
          }
          className="font-medium menu-card-item"
        >
          {t(VISIT_IN_BRANDFOLDER_KEY)}
        </a>
      )}
      <a
        className="font-medium menu-card-item"
        target="_blank"
        rel="noopener noreferrer"
        href="https://help.smartsheet.com/articles/2482587"
      >
        {t(HELP_KEY)}
      </a>
      <a
        className="font-medium menu-card-item"
        target="_blank"
        rel="noopener noreferrer"
        href="https://app.smartsheet.com/b/form/739aa75f30ca43a8a22eb53e4da7d409?What%20is%20your%20feedback%20about%3F=Brandfolder&Origin=com-us"
      >
        {t(SEND_FEEDBACK_KEY)}
      </a>
      <button data-testid="logout" className="font-medium menu-card-item w-full" onClick={onLogout}>
        {t(LOG_OUT_LABEL_KEY)}
      </button>
    </div>
  );
}

export interface MenuPopoutProps {
  assetId: string;
  containerId: string;
}

export function MenuSmartsheet({ assetId, containerId }: MenuPopoutProps) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [-6, 4] } }],
    strategy: 'absolute',
  });

  return (
    <Popover data-testid="main-menu" data-cy="popover-menu" style={{ zIndex: 1000 }}>
      <Popover.Button
        as="button"
        ref={setReferenceElement}
        aria-label="open menu"
        className="icon-kebab-menu-container"
      >
        <span className={classNames('icon-kebab-menu-button', { active: !!popperElement })}>
          <IconKebabMenu />
        </span>
      </Popover.Button>
      <Popover.Panel ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        <Panel assetId={assetId} containerId={containerId} />
      </Popover.Panel>
    </Popover>
  );
}
