import { DI_CONTAINER } from '@integration-frontends/core';
import { assetDetailsPageEntered } from '@integration-frontends/integration/core/application';
import {
  ATTACHMENT_REPO_TOKEN,
  IAttachmentRepo,
} from '@integration-frontends/integration/core/model';
import { call, takeEvery } from 'typed-redux-saga';
import { sendCommand, SmartsheetCommandType } from '../common/smartsheet';

const handler = function* (action: ReturnType<typeof assetDetailsPageEntered>) {
  const attachmentRepo: IAttachmentRepo = DI_CONTAINER.get(ATTACHMENT_REPO_TOKEN);
  const attachments = yield call(attachmentRepo.listAssetAttachments, action.payload.assetId);

  attachments.forEach((attachment) => {
    sendCommand(SmartsheetCommandType.GetAttachment, {
      assetID: action.payload.assetId,
      attachmentID: attachment.id,
    });
  });
};

export function* assetDetailsPageEnteredEffects() {
  yield takeEvery(assetDetailsPageEntered, handler);
}
