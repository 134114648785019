import { put, takeEvery } from 'typed-redux-saga';
import { push } from 'redux-first-history';
import { pageLoadError } from '../../../../libs/common/app/src';

export function createPageLoadErrorRedirectEffect(route: string) {
  function* handler() {
    yield put(push(route));
  }

  return function* () {
    yield takeEvery(pageLoadError, handler);
  };
}
