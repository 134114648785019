import { linkClicked } from '@integration-frontends/common/ui';
import { takeEvery } from 'redux-saga/effects';
import { sendMessage } from '../common/utilities';

function handleLinkClicked(action: ReturnType<typeof linkClicked>) {
  action.payload.external &&
    sendMessage({ event: 'externalLinkClicked', payload: action.payload.href });
}

export function* externalLinkClickedEffects() {
  yield takeEvery(linkClicked, handleLinkClicked);
}
