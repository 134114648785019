import { BFSelectProps } from '@integration-frontends/common/ui';
import React from 'react';
import { Select } from '@smartsheet/lodestar-core';
import './smar-select.scss';

export function SmarSelect({
  onOptionChange,
  options,
  placeholder,
  tabIndex,
  value,
  disabled,
  isClearable,
  ...restProps
}: BFSelectProps) {
  function handleChange(value) {
    onOptionChange(value);
  }

  function getSelectedOption() {
    return options.find((opt) => opt.value === value);
  }

  return (
    <div {...restProps}>
      <Select
        options={options}
        className="smar-select"
        onChange={handleChange}
        value={getSelectedOption()}
        tabIndex={tabIndex?.toString()}
        placeholder={<span className="smar-select-placeholder">{placeholder}</span>}
        formatOptionLabel={(option, labelMeta) => {
          return <span className="smar-select-option">{option.label}</span>;
        }}
        isDisabled={disabled}
        isClearable={isClearable}
      />
    </div>
  );
}
