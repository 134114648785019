import { placeAttachment } from '@integration-frontends/integration/core/application';
import { AttachmentWithDto } from '@integration-frontends/integration/infrastructure/isomorphic';
import { takeEvery, put } from 'redux-saga/effects';
import { sendMessage } from '../common/utilities';
import { sendSegmentAction } from '@integration-frontends/common/analytics';

const handler = function* (action: ReturnType<typeof placeAttachment>) {
  const { url, dto } = action.payload.attachment as AttachmentWithDto;
  sendMessage({
    event: 'selectedAttachment',
    payload: {
      ...dto,
      ...dto.attributes,
      cdn_url: url,
    },
  });
  yield put(sendSegmentAction({ event: `placeAttachment` }));
};

export function* placeAttachmentWorkflow() {
  yield takeEvery(placeAttachment, handler);
}
